/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
@media (min-width: 768px) {
  .grid.grid_cols_2 {
    width: calc(100% + 32px);
  }
}

@media (min-width: 768px) {
  .grid.grid_cols_2 > .grid__item {
    width: calc(50% - 32px);
    margin-right: 32px;
  }
  .grid.grid_cols_2 > .grid__item:last-child {
    margin-right: 0;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.button {
  display: inline-block;
  height: auto;
  min-height: 60px;
  line-height: 60px;
  text-decoration: none;
  text-align: center;
  padding: 0 48px;
  border-radius: 4px;
  transition: background-color 0.2s ease-in-out;
  cursor: pointer;
  border: none;
  appearance: none;
  font-size: 16px;
  color: #fff;
  background: #90268F;
}
.button:hover {
  color: #fff;
  background: #00A54F;
}
.button_size_small {
  height: auto;
  min-height: 40px;
  line-height: 40px;
  padding: 0 24px;
}
.button_width_full {
  width: 100%;
}

.favorite-toggle > svg:nth-child(2) {
  display: none;
}

.favorite-toggle.favorite-toggle_state_active > svg:nth-child(1) {
  display: none;
}
.favorite-toggle.favorite-toggle_state_active > svg:nth-child(2) {
  display: inline-block;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.autocomplete {
  position: relative;
  color: #000;
}
.autocomplete__input {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-height: 48px;
  border: 2px solid #c3c3c4;
  border-radius: 4px;
  background: #fff;
  padding: 12px 10px;
}
.autocomplete__input > input {
  padding: 0;
  outline: 0;
  border: 0;
  background: transparent;
  flex: 100;
  width: 100%;
  min-width: 100px;
  font-size: inherit;
}
.autocomplete__selected {
  color: #fff;
  background: #90268F;
  margin-right: 8px;
  margin-bottom: 4px;
  padding: 2px 0 2px 8px;
  border-radius: 2px;
}
.autocomplete__remove {
  display: inline-block;
  cursor: pointer;
  padding: 0 8px;
}
.autocomplete__results {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.autocomplete__result {
  cursor: pointer;
  padding: 12px 10px;
  color: #000;
}
.autocomplete__result:hover {
  color: #000;
  background: #e6e6e6;
}
.autocomplete__result_town, .autocomplete__result_type_town {
  font-weight: bold;
}

.autocomplete__selected {
  margin-bottom: 0;
  margin-top: 4px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.checkbox-list {
  position: relative;
  color: #000;
}
.checkbox-list__text {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 10px;
  border-radius: 3px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.checkbox-list__text:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.checkbox-list__count {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 2px;
  margin-left: 4px;
  color: #fff;
  background: #90268F;
}
.checkbox-list__options {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.checkbox-list__option {
  position: relative;
  cursor: pointer;
  padding: 12px 10px;
}
.checkbox-list__box {
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  width: 16px;
  height: 16px;
  border: 1px solid #c3c3c4;
  background: #fff;
  border-radius: 2px;
}
.checkbox-list__option.checkbox-list__option_group {
  font-weight: bold;
  text-transform: uppercase;
  cursor: initial;
}
.checkbox-list__option.checkbox-list__option_group > .checkbox-list__box {
  display: none;
}
.checkbox-list__option.checkbox-list__option_checked > .checkbox-list__box {
  border-color: #90268F;
  background: #90268F;
}
.checkbox-list_opened > .checkbox-list__options {
  display: block;
}
.checkbox-list_opened > .checkbox-list__text:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}

.dropdown {
  position: relative;
  color: #000;
}
.dropdown__value {
  position: relative;
  min-height: 32px;
  line-height: 32px;
  padding: 7px 40px 7px 12px;
  border-radius: 4px;
  border: 2px solid #c3c3c4;
  background: #fff;
  cursor: pointer;
}
.dropdown__value:after {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  content: "";
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"/></svg>');
  width: 8px;
  height: 16px;
  right: 20px;
}
.dropdown__options {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 250px;
  overflow: hidden;
  overflow-y: auto;
  border-radius: 2px;
  background: #fff;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
  z-index: 9;
}
.dropdown__option {
  cursor: pointer;
  padding: 12px;
  color: #000;
}
.dropdown__option:hover {
  color: #000;
  background: #e6e6e6;
}
.dropdown__option.dropdown__option_state_selected {
  color: #fff;
  background: #90268F;
}
.dropdown_state_opened > .dropdown__value:after {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="%23000000"><path d="M288.662 352H31.338c-17.818 0-26.741-21.543-14.142-34.142l128.662-128.662c7.81-7.81 20.474-7.81 28.284 0l128.662 128.662c12.6 12.599 3.676 34.142-14.142 34.142z"/></svg>');
}
.dropdown_state_closed > .dropdown__options {
  display: none;
}
.dropdown_state_opened > .dropdown__options {
  display: block;
}

.switcher {
  display: inline-flex;
  position: relative;
  cursor: pointer;
  align-items: center;
  color: #000;
}
.switcher__control {
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 48px;
  height: 28px;
  border-radius: 16px;
  background-color: #efeeef;
  transition: background-color 0.3s ease;
}
.switcher__control:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #fff;
  transition: background-color 0.3s ease, left 0.3s ease;
}
.switcher__label {
  display: inline-block;
  margin-left: 12px;
}
.switcher_state_checked > .switcher__control {
  background-color: #90268F;
}
.switcher_state_checked > .switcher__control:after {
  left: 24px;
  background-color: #fff;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.search-bar {
  position: relative;
}
.search-bar__container {
  width: 280px;
  margin: 0 auto;
}
.search-bar__main-options {
  position: relative;
  padding: 16px;
  border-radius: 3px;
  background-color: #fff;
}
.search-bar__main-options .dropdown__value,
.search-bar__main-options .checkbox-list__text,
.search-bar__main-options .autocomplete__input {
  background-color: transparent;
  border: none;
  padding: 0;
}
.search-bar__main-options .autocomplete__input {
  min-height: 32px;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list {
  border-bottom: 1px solid #ececec;
}
.search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list,
.search-bar__main-options .autocomplete {
  padding: 32px 0 16px;
}
.search-bar__main-options .dropdown:after,
.search-bar__main-options .checkbox-list:after,
.search-bar__main-options .autocomplete:after {
  position: absolute;
  top: 16px;
  left: 0;
  font-size: 11px;
  font-weight: bold;
  color: #000;
}
.search-bar__main-options .dropdown:after {
  content: "STATUS";
}
.search-bar__main-options .checkbox-list:after {
  content: "TYPE";
}
.search-bar__main-options .autocomplete:after {
  content: "LOCATION";
}
.search-bar__secondary-options {
  display: none;
  margin-top: 64px;
  padding: 16px 16px 0;
  background-color: #fff;
  border-radius: 3px;
  border-top: 4px solid #90268F;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .checkbox-list__text {
  border: none;
  background-color: #efefef;
  border-radius: 2px;
  min-height: 24px;
  line-height: 24px;
}
.search-bar__secondary-options .switcher {
  display: flex;
  margin-top: 16px;
}
.search-bar__secondary-options .dropdown__value,
.search-bar__secondary-options .dropdown__options,
.search-bar__secondary-options .checkbox-list__text,
.search-bar__secondary-options .checkbox-list__options,
.search-bar__secondary-options .switcher {
  font-size: 14px;
}
.search-bar__secondary-options .dropdown + .dropdown,
.search-bar__secondary-options .dropdown + .checkbox-list {
  margin-top: 12px;
}
.search-bar__secondary-options_state_open {
  display: block;
}
.search-bar__options {
  position: absolute;
  bottom: -32px;
  right: 0;
  font-size: 12px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px dotted #fff;
}
.search-bar__options:hover {
  color: #fff;
  border-color: #fff;
}
.search-bar__submit {
  padding: 16px 0;
  border-radius: 2px;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  background-color: #90268F;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}
.search-bar__submit:hover {
  color: #fff;
  background-color: #00A54F;
}
.search-bar .clearfix {
  display: block;
  width: 100%;
}
@media (min-width: 375px) {
  .search-bar__container {
    width: 320px;
  }
}
@media (min-width: 425px) {
  .search-bar__container {
    width: 350px;
  }
}
@media (min-width: 768px) {
  .search-bar__container {
    width: 720px;
  }
  .search-bar__main-options {
    display: flex;
    padding: 0 0 0 16px;
  }
  .search-bar__main-options .dropdown {
    flex-basis: 160px;
    margin-right: 16px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 180px;
    margin-right: 16px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 240px;
  }
  .search-bar__main-options .dropdown,
.search-bar__main-options .checkbox-list {
    border-bottom: none;
  }
  .search-bar__main-options .dropdown:before,
.search-bar__main-options .checkbox-list:before {
    content: "";
    position: absolute;
    top: 24px;
    right: 0;
    width: 1px;
    height: 32px;
    border-right: 1px solid #ececec;
  }
  .search-bar__submit {
    flex-grow: 1;
    padding-top: 28px;
    border-radius: 0 3px 3px 0;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
.search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(50% - 8px);
    flex-shrink: 1;
    flex-grow: 0;
    margin-bottom: 16px;
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 0;
  }
  .search-bar__secondary-options_state_open .dropdown + .dropdown,
.search-bar__secondary-options_state_open .dropdown + .checkbox-list {
    margin-top: 0;
  }
  .search-bar__secondary-options_state_open .switcher {
    margin-top: 0;
    margin-bottom: 16px;
    margin-right: 32px;
  }
}
@media (min-width: 1024px) {
  .search-bar__container {
    width: 920px;
  }
  .search-bar__main-options .checkbox-list {
    flex-basis: 225px;
  }
  .search-bar__main-options .autocomplete {
    flex-basis: 355px;
  }
  .search-bar__secondary-options_state_open {
    display: flex;
    flex-wrap: wrap;
  }
  .search-bar__secondary-options_state_open .dropdown,
.search-bar__secondary-options_state_open .checkbox-list {
    flex-basis: calc(25% - 12px);
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(2n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(2n) {
    margin-right: 16px;
  }
  .search-bar__secondary-options_state_open .dropdown:nth-child(4n),
.search-bar__secondary-options_state_open .checkbox-list:nth-child(4n) {
    margin-right: 0;
  }
  .search-bar__options {
    right: 22px;
  }
}

.hero {
  position: relative;
}
.hero__heading, .hero__subheading {
  position: relative;
  padding: 0 16px;
  max-width: 540px;
  color: #fff;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  margin: 0 auto 48px;
}
.hero__heading + .hero__subheading {
  margin-top: -32px;
}
.hero__actions {
  margin-top: 16px;
}
@media (min-width: 768px) {
  .hero__heading, .hero__subheading {
    max-width: 100%;
  }
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.gallery__canvas {
  position: relative;
}

.gallery__images, .gallery__image, .gallery__shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery__image {
  opacity: 0;
}

.gallery__image_with_transition {
  transition: opacity 0.3s ease;
}

.gallery__image_timeline_current {
  opacity: 1;
}

.gallery__shadow {
  background: rgba(0, 0, 0, 0.5);
}

.gallery__dots {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  width: 80%;
  text-align: center;
}

.gallery__dot {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  background-color: #fff;
  cursor: pointer;
}

.gallery__dot_state_active {
  background-color: #221e1f;
}

.gallery__dot + .gallery__dot {
  margin-left: 8px;
}

.gallery__control {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 50%;
  border-radius: 50%;
  background-color: #90268F;
  cursor: pointer;
}

.gallery__control > svg {
  fill: #fff;
  width: 16px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.gallery__control:hover {
  background-color: #221e1f;
}

.gallery__control:hover > svg {
  fill: #fff;
}

.gallery__control_type_prev {
  left: 0;
  transform: translate(-25%, -50%);
}

.gallery__control_type_next {
  right: 0;
  transform: translate(25%, -50%);
}

.gallery[data-with-origin] .gallery__image {
  cursor: pointer;
}

.gallery__stripe {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
  color: #000;
}

.gallery__title {
  flex: 1 1 0;
}

.gallery__counter {
  flex: 0 0 auto;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.hero__gallery {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.hero__gallery > .gallery__canvas {
  width: 100%;
  height: 100%;
}

.hero .gallery__dots {
  display: none;
}

.hero__heading + .hero__search-bar {
  margin-bottom: 48px;
  padding-top: 0;
}

.hero_type_gallery-and-search {
  width: 100%;
  flex-basis: 780px;
  flex-shrink: 0;
}

.hero_type_gallery-and-search > .hero__heading {
  text-align: center;
  font-size: 36px;
}

.hero_type_gallery-and-search > .hero__subheading {
  text-align: center;
  font-size: 24px;
  margin-bottom: 0;
}

@media (min-width: 375px) {
  .hero_type_gallery-and-search {
    flex-basis: 730px;
  }
}
@media (min-width: 425px) {
  .hero_type_gallery-and-search {
    flex-basis: 680px;
  }
}
@media (min-width: 768px) {
  .hero_type_gallery-and-search {
    flex-basis: 510px;
    padding-top: 150px;
  }

  .hero .gallery__dots {
    display: block;
  }
}
.hero_type_gallery-and-search {
  flex-basis: 730px;
}

.hero_type_gallery-and-search > .hero__gallery .gallery__image > img {
  object-position: center top;
}

@media (min-width: 425px) {
  .hero_type_gallery-and-search {
    flex-basis: 680px;
  }
}
@media (min-width: 768px) {
  .hero_type_gallery-and-search {
    flex-basis: 640px;
    padding-top: 300px;
  }
}
@media (min-width: 1024px) {
  .hero_type_gallery-and-search {
    flex-basis: 630px;
  }
}
/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.property-card {
  display: block;
  position: relative;
  text-decoration: none;
  color: #000;
}
.property-card__tags {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  padding-bottom: 8px;
}
.property-card__tag {
  color: #fff;
  background: #90268F;
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 2px;
  text-align: center;
  line-height: 20px;
}
.property-card__favorite {
  position: absolute;
  top: -6px;
  left: 24px;
  z-index: 2;
  width: 40px;
  height: 40px;
  border-radius: 0 0 2px 2px;
  color: #fff;
  background: #00A54F;
  cursor: pointer;
}
.property-card__favorite:after {
  content: "";
  position: absolute;
  top: 0;
  right: -6px;
  height: 0;
  width: 0;
  border-bottom: 6px solid #007237;
  border-right: 6px solid transparent;
}
.property-card__favorite > svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  fill: currentColor;
}
.property-card__favorite:hover {
  color: #fff;
  background: #90268F;
}
.property-card__primary-details {
  padding: 8px 16px;
  color: #fff;
  background-color: #90268F;
}
.property-card__price {
  font-size: 0;
}
.property-card__currency, .property-card__amount, .property-card__units, .property-card__period {
  font-size: 18px;
  font-weight: bold;
}
.property-card__amount, .property-card__units, .property-card__period {
  padding-left: 4px;
}
.property-card__summary {
  font-size: 14px;
}
.property-card__area {
  font-size: 14px;
}
.property-card__secondary-details {
  padding: 12px 16px;
}
.property-card__heading {
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.property-card__description {
  margin-top: 8px;
  font-size: 12px;
}
.property-card__features {
  display: flex;
  flex-wrap: wrap;
  margin-top: 8px;
}
.property-card__feature {
  display: flex;
  flex: 0 0 auto;
  line-height: 40px;
  margin-right: 10px;
}
.property-card__feature:last-child {
  margin-right: 0;
}
.property-card__feature-icon {
  margin-right: 4px;
}
.property-card__feature-icon > svg {
  position: relative;
  top: -2px;
  width: 18px;
  vertical-align: middle;
  fill: #000;
}
.property-card__feature-value {
  font-size: 14px;
}
.property-card__feature_type_beds svg {
  top: -1px;
  width: 22px;
}
.property-card__feature_type_baths svg {
  width: 18px;
}
.property-card__feature_type_stand svg {
  width: 20px;
}
.property-card__active-area {
  position: relative;
  z-index: 1;
  height: 100%;
  background-color: #f6f7f9;
  border: 1px solid #efefef;
  border-radius: 4px;
  overflow: hidden;
}
.property-card__active-area:hover .property-card__primary-details {
  color: #fff;
  background-color: #00A54F;
}

.property-card__address {
  display: flex;
  margin-top: 12px;
  font-size: 14px;
  font-style: italic;
}
.property-card__address-icon {
  width: 16px;
  margin-right: 4px;
}

.property-card__address_type_country svg {
  position: relative;
  top: 1px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.property-card__photos {
  position: relative;
}
.property-card__photo {
  position: relative;
  width: 100%;
  font-size: 0;
  line-height: 0;
  overflow: hidden;
}
.property-card__photo > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.property-card__control {
  position: absolute;
  top: 0;
  width: 60px;
  height: 100%;
}
.property-card__control > svg {
  width: 10px;
  fill: #fff;
  filter: drop-shadow(0rem 0.125rem 0.1875rem rgba(0, 0, 0, 0.25));
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.property-card__control:hover > svg {
  fill: #90268F;
}
.property-card__control_type_prev {
  left: 0;
}
.property-card__control_type_prev > svg {
  left: 8px;
}
.property-card__control_type_next {
  right: 0;
}
.property-card__control_type_next > svg {
  right: 8px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.tool-card {
  text-decoration: none;
}
.tool-card__icon {
  position: relative;
  top: 0;
  width: 50px;
  margin: 0 auto 16px;
  transition: top 0.3s ease;
}
.tool-card__icon > svg {
  width: 100%;
  fill: #fff;
}
.tool-card__heading {
  color: #fff;
  font-weight: bold;
  text-align: center;
  margin-bottom: 8px;
}
.tool-card__description {
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  line-height: 28px;
  text-align: center;
}
.tool-card:hover > .tool-card__icon {
  top: -8px;
}
@media (min-width: 425px) {
  .tool-card {
    display: flex;
  }
  .tool-card__icon {
    flex-shrink: 0;
    margin: 0 24px 0 0;
  }
  .tool-card__icon > svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .tool-card__heading, .tool-card__description {
    text-align: left;
  }
}
@media (min-width: 768px) {
  .tool-card__icon {
    width: 56px;
  }
}
@media (min-width: 1200px) {
  .tool-card__icon {
    margin-right: 40px;
  }
}

.tool-card__heading {
  font-size: 20px;
}

/*
To add new icon to the set:
1) go to the `warehouse/icons/awesome.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/ion.scss`
2) find required icon
3) copy line to this file
*/
/*
To add new icon to the set:
1) go to the `warehouse/icons/thin.scss`
2) find required icon
3) copy line to this file
*/
.article-card {
  margin-bottom: 64px;
}
.article-card__main-link {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.article-card__image {
  border-radius: 4px;
  overflow: hidden;
}
.article-card__heading {
  font-size: 18px;
  font-weight: bold;
  margin-top: 24px;
  color: #000;
}
.article-card__summary {
  margin-top: 12px;
  color: #00A54F;
  font-size: 14px;
}
.article-card__category {
  position: relative;
  z-index: 2;
  text-decoration: none;
  color: #90268F;
  font-size: 14px;
}
.article-card__category:hover {
  color: #000;
}
.article-card__date {
  font-size: 14px;
}
.article-card__more {
  display: inline-block;
  font-size: 14px;
  margin-top: 20px;
  border-bottom: 2px solid;
  color: #221b1d;
}
.article-card__group {
  margin: 12px 0 20px;
  padding: 8px 12px;
  border-radius: 2px;
  background-color: #efefef;
}
.article-card__group > .article-card__date {
  display: inline-block;
  margin-right: 8px;
}
.article-card__main-link:hover ~ .article-card__details > .article-card__heading {
  color: #000;
}
.article-card__main-link:hover ~ .article-card__details > .article-card__summary {
  color: #00A54F;
}
.article-card__main-link:hover ~ .article-card__details > .article-card__more {
  color: #000;
}
.article-card__heading + .article-card__date {
  margin-top: 12px;
}
@media (min-width: 768px) {
  .article-card {
    margin-bottom: 32px;
  }
}
@media (min-width: 1200px) {
  .article-card {
    display: flex;
  }
  .article-card__image-container {
    flex-basis: 230px;
    flex-shrink: 0;
    margin-right: 16px;
  }
  .article-card__heading {
    margin-top: 16px;
  }
}

.article-card__details {
  overflow: hidden;
}

.article-card__heading {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@media (min-width: 1200px) {
  .article-card__heading {
    margin-top: 0;
  }
}

#help-story.section {
  background-color: #d62f74;
}
#help-story.section .section__heading {
  color: #fff;
}
#help-story.section .section__paragraph {
  color: rgba(255, 255, 255, 0.8);
}
#help-story.section .image {
  height: 300px;
}
@media (min-width: 768px) {
  #help-story.section {
    padding: 0;
  }
  #help-story.section .image {
    height: 100%;
  }
  #help-story.section img {
    object-position: bottom;
  }
  #help-story.section .grid__item:nth-child(2) {
    padding: 70px 0;
  }
}

#sell-story.section {
  background-color: #ffcf00;
}
#sell-story.section .image {
  height: 300px;
}
@media (min-width: 768px) {
  #sell-story.section {
    padding: 0;
  }
  #sell-story.section .image {
    height: 100%;
  }
  #sell-story.section img {
    object-position: bottom;
  }
  #sell-story.section .grid__item:nth-child(1) {
    padding-top: 48px;
  }
  #sell-story.section .grid__item:nth-child(2) {
    padding: 70px 0;
  }
}

#what-story.section {
  background-color: #0096ff;
}
#what-story.section .section__heading {
  color: #fff;
}
#what-story.section .section__paragraph {
  color: rgba(255, 255, 255, 0.8);
}
#what-story.section .image {
  height: 300px;
}
@media (min-width: 768px) {
  #what-story.section {
    padding: 0;
  }
  #what-story.section .image {
    height: 100%;
  }
  #what-story.section .grid__item:nth-child(2) {
    padding: 70px 0;
  }
}
@media (min-width: 1200px) {
  #what-story.section img {
    object-position: bottom;
  }
  #what-story.section .grid__item:nth-child(2) {
    padding: 140px 0;
  }
}

@media (min-width: 768px) {
  #tools-story.section .tool-card:nth-child(1),
#tools-story.section .tool-card:nth-child(2) {
    margin-bottom: 72px;
  }
}
@media (min-width: 1200px) {
  #tools-story.section .tool-card:nth-child(1),
#tools-story.section .tool-card:nth-child(2) {
    margin-bottom: 96px;
  }
}

#tools-story.section .tool-card__icon {
  width: 90px;
  height: 90px;
  padding: 20px;
  border-radius: 50%;
  border: 3px solid;
}
#tools-story.section .tool-card__icon > svg {
  width: auto;
}
#tools-story.section .tool-card:nth-child(1) > .tool-card__icon {
  border-color: #ffcf00;
}
#tools-story.section .tool-card:nth-child(2) > .tool-card__icon {
  border-color: #d72d72;
}
#tools-story.section .tool-card:nth-child(3) > .tool-card__icon {
  border-color: #48caca;
}
#tools-story.section .tool-card:nth-child(4) > .tool-card__icon {
  border-color: #38d305;
}